import Vue from 'vue'
import App from './App.vue'
import router from './router'//路由
import store from './store'//vuex
import ElementUI from 'element-ui';//elemnt-ui
import 'element-ui/lib/theme-chalk/index.css';
import apiFun from "@/request/api.js"; // 全局挂载请求
Vue.prototype.$apiFun = apiFun;//请求接口api
Vue.config.productionTip = false


import 'amfe-flexible';
import { i18n } from './i18n/index' //国际化

Vue.use(ElementUI);
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
