const product = {
    author: "作者",
    publisher: "出版社",
    publication_date: "出版日期",
    price: "价格",
    price2: '售價',
    select_all: '全選 ',
    total_price: '總價',
    freight: '(不含運費)',
    checkout: '結帳付款',
    language: '語言',
    content_validity: '內容簡介',
    buy: '結帳付款',
    quantity: '數量',
    order_price: '訂單價格',
    pay_service_fee: '支付服務費',
    freight2: '運費',
    total_order_amount: '總訂單金額',
    choose_payment_method: '選擇付款方式',
    unionPay_card: '銀聯卡',
    alipay: '支付寶',
    total_order_amount: '總訂單金額',
    Saved: '已節省',
    confirm: '確認',
    forgot_password: '忘記密碼',
    no_account: '還沒有帳戶',
    register_now: '馬上註冊',
    to_be_shipped: '待發貨',
    to_be_received: '待收貨',
    order_date: '下單日期',
    actual_payment: '實付款',
    cancel_order: '取消訂單',
    text: '已提醒商家發貨',
    text2: '平臺將主動幫您跟進訂單發貨',
    sign_for: '已簽收',
    already_on: '已於',
    delivery: '送達',
    sales_promotion: '促銷',
    confirm_receipt: '確認收貨',
    after_sales: '退換售後',
    order_number: '訂單編號',
    delivery_date: '發貨日期',
    package_number: '包裹編號',
    password_text: '請輸入新密碼並驗證手機號',
    verification_code: '獲取驗證碼',
    common_qa_title: '常見問答標題名稱?',
    novice_text: '後臺編輯資訊上傳',
    novice_text2: '新手上路/送與支付/售後服務保證/費者告知/服務條款與免責聲明/關於我們信息',
    dial: '一鍵撥打',
    car_tip: '購物車是空的~',

    Simplified_chinese: '简体中文',
    Traditional_chinese: '繁体中文',
    Japanese: '日文',
    login_now: '馬上登入',
    account:'已有帳戶',
    phone_number:'請輸入入手機驗證碼',
    register:'註冊',
    phone_number2:'請輸入手機號',
    new_password:'新密碼（6-25比特數位字母）',
    new_password_ok:'確認新密碼',
    reset_password:'重置密碼' 
}
export default product;
