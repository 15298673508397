import Vue from "vue"
import VueI18n from "vue-i18n"
import zh_cn from "./lang/zh_cn"
import zh_tw from "./lang/zh_tw"
import en_us from "./lang/en_us"

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "zh_cn", // 从localStorage中获取 默认英文
  messages: {
    zh_cn: zh_cn,
    zh_tw: zh_tw,
    en_us: en_us,
  }
});

export default i18n;