const home = {
    sale_top: "暢銷書排行",
    recommend_list: "推薦圖書單品",
    new_list: "最新出版漫畫書",

    shopping_cart:"購物車",
    product_details:"商品詳情",
    checkout:'結帳付款',
    member_center:'會員中心',
    personal_data:'個人資料',
    order_management:'訂單管理',
    forgot_password:'忘記密碼',
    help_center:'幫助中心',
    contact_customer_service:'聯絡客服',
    common_qa:'常見問答',
    delivery_and_payment:'配送與支付',
    after_sales_service_guarantee:'售後服務保證',
    consumer_notification:'消費者告知',
    terms_of_service_and_disclaimer:'服務條款與免責聲明',
    contact_us:'聯絡我們',
    regarding_us:'關於我們',
    login_registration:'登入/註冊',
    welcome_to:'歡迎來到',
    quit:'登出',
    login:'登入',
    phone_number:'手機門號',
    personal_data:'個人資料',
    real_name:'真實姓名',
    zip:'郵編',
    email:'郵箱',
    order_details:'訂單詳情',
    search_result:'搜索結果',
    related_books:'推薦相關圖書',
    area_name:'所在地區',
    dz:'詳細地址',
    wx:'微信ID',

}
export default home;
