import router from "@/router";
import axios from "axios"; //引入
import { getAuthToken } from "@/utils/auth";
// import { getToken } from "@/utils/token";
import { Message } from "element-ui"; // 导入自己要用的ui组件库
import { MessageBox } from 'element-ui';
let baseURL = process.env.VUE_APP_BASEURL;

//这一步的目的是判断出当前是开发环境还是生成环境，方法不止一种，达到目的就行
if (process.env.NODE_ENV == "development") {
    // 开发环境
    // baseURL = 'https://mock.mengxuegu.com/mock/627fb7a24554ef2f96d4f0d7/shop'
    // baseURL='http://book.cc/';
    //   baseURL='http://sctest.onefa.cn/';
    //   baseURL='http://223.27.39.82:8801/';
    baseURL = 'https://admin.tcme.info/';
} else {
    // 生产环境
    // baseURL = 'https://mock.mengxuegu.com/mock/62f323bef2652f239bd0a4a8/shopshengchan'
}

let config = {
    baseURL: baseURL,
    timeout: 30000, //设置最大请求时间
};
const _axios = axios.create(config);

/* 请求拦截器（请求之前的操作） */
_axios.interceptors.request.use(
    (config) => {
        //如果有需要在这里开启请求时的loading动画效果
        config.headers.Token = getAuthToken(); //添加token,需要结合自己的实际情况添加，
        return config;
    },
    (err) => Promise.reject(err)
);

/* 请求之后的操作 */
_axios.interceptors.response.use(
    (res) => {
        //关闭请求时的loading动画
        //返回401无权限，可能会是跳回到登录页的操作，结合项目写
        if (res.data.code === 401) {
            router.push({ path: '/login' })
            Message.error("無權限操作");
        }
        if(res.data.code===0){
            // Message.error(res.data.msg);


            MessageBox(res.data.msg, '提示', {
                confirmButtonText: '確定',
                cancelButtonText: '取消',
                type: 'warning',
                customClass: "focus-message",
            }).then(() => {

            }).catch(() => {

            });
        }

        if (res.data.code === 400) {
            Message.error("請求網絡失敗");
        }
        if (res.data.code === 404) {
            Message.error("請求網絡失敗");
        }
        return res.data; // 按需导出res的字段
    },
    (err) => {
        if (err) {
            if (err?.response?.status == "401") {

                MessageBox('請前往登錄頁登錄後查看', '提示', {
                    confirmButtonText: '確定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    customClass: "focus-message",
                }).then(() => {
                    router.push({ path: '/login' })

                }).catch(() => {

                });

                return Promise.resolve({
                    code: 401,
                    data: null,
                    msg: "請登錄後操作",
                    time: "1718685436"
                });
            }
            //在这里关闭请求时的loading动画效果
            Message.error("接口請求錯誤");
        }

        // return Promise.reject(err);
        return Promise.reject(err);
    }
);


// methods可变的请求方式
const request = (methods, url = "", params = {}) => {
    let paramsRequest = {
        url,
        headers: { "Content-Type": "application/json;charset=UTF-8" },
        method: methods,
    };
    // get请求使用params
    if (methods === "get" || methods === "GET") {
        paramsRequest.params = params;
    } else {
        // post put 请求使用data
        paramsRequest.data = params;
    }
    return new Promise((resolve, reject) => {
        _axios(paramsRequest)
            .then((res) => {
                resolve(res);
                return res;
            })
            .catch((error) => {
                reject(error);
            });
    });
};
export default request;
